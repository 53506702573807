// src/components/PeakLoadShavingScreen.jsx
import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
  ReferenceLine,
} from 'recharts';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { Table } from 'antd'; // Ant Design for the table component

const PeakLoadShavingScreen = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [totalNetConsumption, setTotalNetConsumption] = useState(0);
  const [totalFromGrid, setTotalFromGrid] = useState(0);
  const [totalToGrid, setTotalToGrid] = useState(0);
  const [peakLoads, setPeakLoads] = useState([]);
  const peakShavingLimit = 8; // 8 kW limit

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://menea.krtech.dev/api/energy-data');
        const rawData = response.data;

        // Process data
        const processedData = rawData.map((item) => ({
          time: moment(item.time).format('HH:mm'),
          gridEnergy: item.gridEnergy,
          homeUsage: item.homeUsage,
          batteryEnergy: item.batteryEnergy,
          withBattery: item.withBattery,
          withoutBattery: item.withoutBattery,
        }));

        setData(processedData);

        // Calculate total net consumption
        const totalNet = processedData.reduce((acc, curr) => acc + curr.gridEnergy, 0);
        setTotalNetConsumption(totalNet.toFixed(2));

        // Calculate total energy from grid and to grid
        const fromGrid = processedData
          .filter((item) => item.gridEnergy > 0)
          .reduce((acc, curr) => acc + curr.gridEnergy, 0);
        setTotalFromGrid(fromGrid.toFixed(2));

        const toGrid = processedData
          .filter((item) => item.gridEnergy < 0)
          .reduce((acc, curr) => acc + curr.gridEnergy, 0);
        setTotalToGrid(Math.abs(toGrid).toFixed(2)); // Absolute value since it's negative

        // Identify peak loads
        const peakLoadData = processedData
          .filter((item) => item.gridEnergy > 0) // Only consider positive gridEnergy
          .sort((a, b) => b.gridEnergy - a.gridEnergy) // Sort descending
          .slice(0, 10); // Get top 10 peaks

        // Calculate percentage contribution for each peak load
        const peakLoadsWithPercentage = peakLoadData.map((item) => {
          const percentage = ((item.gridEnergy / fromGrid) * 100).toFixed(2);
          return {
            key: item.time,
            time: item.time,
            peakLoad: item.gridEnergy.toFixed(2),
            percentage: percentage,
          };
        });

        // Sort by percentage
        peakLoadsWithPercentage.sort((a, b) => b.percentage - a.percentage);

        setPeakLoads(peakLoadsWithPercentage);
      } catch (error) {
        console.error('Error fetching energy data:', error);
      }
    };

    fetchData();
  }, []);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  const summaryStyle = {
    textAlign: 'center',
    marginBottom: '20px',
    fontSize: '18px',
  };

  // Table columns
  const columns = [
    {
      title: t('time'),
      dataIndex: 'time',
      key: 'time',
    },
    {
      title: `${t('peakLoad')} (kW)`,
      dataIndex: 'peakLoad',
      key: 'peakLoad',
      sorter: (a, b) => parseFloat(a.peakLoad) - parseFloat(b.peakLoad),
    },
    {
      title: `${t('percentageContribution')} (%)`,
      dataIndex: 'percentage',
      key: 'percentage',
      sorter: (a, b) => parseFloat(a.percentage) - parseFloat(b.percentage),
    },
  ];

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('peak_load_shaving')}</h2>
      <div style={summaryStyle}>
        <p>
          {t('netConsumptionOnNetwork')}: {totalNetConsumption} kWh
        </p>
      </div>

      {/* Energy Flow Chart */}
      <h3 style={titleStyle}>{t('energyFlow')}</h3>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer>
            <AreaChart data={data}>
              <defs>
                <linearGradient id="colorGridEnergy" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorWithoutBattery" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="time" />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              {/* Peak Shaving Import Limit */}
              <ReferenceLine
                y={peakShavingLimit}
                label={`${t('peakShavingLimit')} (${peakShavingLimit} kW)`}
                stroke="red"
                strokeDasharray="5 5"
              />
              {/* Energy imported from grid */}
              <Area
                type="monotone"
                dataKey="gridEnergy"
                stroke="#8884d8"
                fillOpacity={1}
                fill="url(#colorGridEnergy)"
                name={t('gridEnergy')}
              />
              {/* Energy usage without battery */}
              <Area
                type="monotone"
                dataKey="withoutBattery"
                stroke="#82ca9d"
                fillOpacity={1}
                fill="url(#colorWithoutBattery)"
                name={t('withoutBattery')}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      </div>

      {/* Energy Sources Breakdown */}
      <h3 style={titleStyle}>{t('energySourcesBreakdown')}</h3>
      <div style={summaryStyle}>
        <p>
          {t('fromGrid')}: {totalFromGrid} kWh
        </p>
        <p>
          {t('toGrid')}: {totalToGrid} kWh
        </p>
      </div>

      {/* Peak Loads Table */}
      <h3 style={titleStyle}>{t('significantPeakLoads')}</h3>
      <div style={{ padding: '0 20px' }}>
        <Table
          columns={columns}
          dataSource={peakLoads}
          pagination={false}
          locale={{ emptyText: t('noData') }}
        />
      </div>
    </div>
  );
};

export default PeakLoadShavingScreen;
