// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';



const resources = {
  en: {
    translation:{
  "loading": "Loading...",
  "inverterData": "Inverter Data",
  "todayProduction": "Today's Production",
  "monthlyProduction": "Monthly Production",
  "yearlyProduction": "Yearly Production",
  "production": "Production",
  "power": "Power",
  "energy": "Energy",
  "time": "Time",
  "month": "Month",
  "year": "Year",
  "powerKw": "Power (kW)",
  "energyKwh": "Energy (kWh)",
  "batteryStatusLast60Minutes": "Battery Status - Last 60 Minutes",
  "batteryStatusExplanation60Minutes": "This graph shows the battery charge level over the past 60 minutes.",
  "batteryStatusLast24Hours": "Battery Status - Last 24 Hours",
  "batteryStatusExplanation24Hours": "This graph shows the battery charge level over the past 24 hours.",
  "batteryStatusLast30Days": "Battery Status - Last 30 Days",
  "batteryStatusExplanation30Days": "This graph shows the battery charge level over the past 30 days.",
  "batteryChargeLevel": "Charge Level (%)",
  "date": "Date",
  "evChargingLast12Months": "EV Charging - Last 12 Months",
  "evChargingExplanation": "This graph shows the amount of energy used for charging electric vehicles over the past 12 months.",
  "chargingKwh": "Charging (kWh)",
  "HR": "HR",
  "EN": "EN",
  "today": "Today",
  "last30Days": "Last 30 Days",
  "lastYear": "Last Year",
  "electricEnergyConsumption": "Electric Energy Consumption",
  "last24Hours": "Last 24 Hours",
  "dayTariff": "Day Tariff",
  "nightTariff": "Night Tariff",
  "perDay": "Per Day",
  "last12Months": "Last 12 Months",
  "thermalEnergyConsumption": "Thermal Energy Consumption",
  "pyrolyticFurnace": "Pyrolytic Furnace",
  "heatPump": "Heat Pump",
  "gasConsumption": "Gas Consumption",
  "waterConsumption": "Water",
  "peak_load_shaving": "Peak Load Shaving",
  "netConsumptionOnNetwork": "Net Consumption on the Network",
  "energyFlow": "Energy Flow (From and To the Grid)",
  "peakShavingLimit": "Peak Shaving Import Limit",
  "gridEnergy": "Grid Energy",
  "withoutBattery": "Without Battery",
  "withBattery": "With Battery",
  "energySourcesBreakdown": "Energy Sources Breakdown",
  "fromGrid": "From Grid",
  "toGrid": "To Grid",
  "peakLoad": "Peak Load",
  "percentageContribution": "Percentage Contribution",
  "significantPeakLoads": "Most Significant Peak Load Consumptions",
  "noData": "No data available",
  "peakLoadWithoutBattery": "Peak Load Without Battery",
  "peakLoadWithBattery": "Peak Load With Battery",
  "peakShaved": "Peak Shaved",
  "significantPeakShavings": "Most Significant Peak Shavings",
  "electric_energy": "Electric Energy",
  "thermal_energy": "Thermal Energy",
  "water_consumption": "Water Consumption",
  "ev_charging": "EV Charging",
  "inverter": "Solar Power Collector",
  "battery": "Battery",
  "batterySoC": "Battery State of Charge (%)",
  "stateOfCharge": "State of Charge",
  "chargingPowerKw": "Charging Power (kW)",
  "dischargingPowerKw": "Discharging Power (kW)",
  "batteryPowerKw": "Battery Power (kW)",
  "consumptionKw": "Consumption (kW)",
  "generationKw": "Generation (kW)",
  "importKw": "Import (kW)",
  "exportKw": "Export (kW)"
}


  
  },
  hr: {
    translation: {
  "loading": "Učitavanje...",
  "inverterData": "Podaci Solarne elektrane",
  "todayProduction": "Današnja proizvodnja",
  "monthlyProduction": "Mjesečna proizvodnja",
  "yearlyProduction": "Godišnja proizvodnja",
  "production": "Proizvodnja",
  "power": "Snaga",
  "energy": "Energija",
  "time": "Vrijeme",
  "month": "Mjesec",
  "year": "Godina",
  "powerKw": "Snaga (kW)",
  "energyKwh": "Energija (kWh)",
  "batteryStatusLast60Minutes": "Status baterije - Zadnjih 60 minuta",
  "batteryStatusExplanation60Minutes": "Ovaj graf prikazuje razinu napunjenosti baterije u posljednjih 60 minuta.",
  "batteryStatusLast24Hours": "Status baterije - Zadnja 24 sata",
  "batteryStatusExplanation24Hours": "Ovaj graf prikazuje razinu napunjenosti baterije u posljednja 24 sata.",
  "batteryStatusLast30Days": "Status baterije - Zadnjih 30 dana",
  "batteryStatusExplanation30Days": "Ovaj graf prikazuje razinu napunjenosti baterije u posljednjih 30 dana.",
  "batteryChargeLevel": "Napunjenost (%)",
  "date": "Datum",
  "evChargingLast12Months": "Punjenje električnih vozila - Zadnjih 12 mjeseci",
  "evChargingExplanation": "Ovaj graf prikazuje količinu energije korištene za punjenje električnih vozila u posljednjih 12 mjeseci.",
  "chargingKwh": "Punjenje (kWh)",
  "HR": "HR",
  "EN": "EN",
  "today": "Danas",
  "last30Days": "Zadnjih 30 dana",
  "lastYear": "Zadnja godina",
  "electricEnergyConsumption": "Potrošnja električne energije",
  "last24Hours": "Zadnja 24 sata",
  "dayTariff": "Dnevna tarifa",
  "nightTariff": "Noćna tarifa",
  "perDay": "Po danu",
  "last12Months": "Zadnjih 12 mjeseci",
  "thermalEnergyConsumption": "Potrošnja toplinske energije",
  "pyrolyticFurnace": "Pirolitička peć",
  "heatPump": "Toplinska pumpa",
  "gasConsumption": "Potrošnja plina",
  "waterConsumption": "Vode",
  "peak_load_shaving": "Smanjenje vršnog opterećenja",
  "netConsumptionOnNetwork": "Neto potrošnja na mreži",
  "energyFlow": "Tok energije (od i prema mreži)",
  "peakShavingLimit": "Granica smanjenja vršnog opterećenja",
  "gridEnergy": "Energija iz mreže",
  "withoutBattery": "Bez baterije",
  "withBattery": "S baterijom",
  "energySourcesBreakdown": "Pregled izvora energije",
  "fromGrid": "Iz mreže",
  "toGrid": "Prema mreži",
  "peakLoad": "Vršno opterećenje",
  "percentageContribution": "Postotni doprinos",
  "significantPeakLoads": "Najznačajnije vršne potrošnje",
  "noData": "Nema dostupnih podataka",
  "peakLoadWithoutBattery": "Vršno opterećenje bez baterije",
  "peakLoadWithBattery": "Vršno opterećenje s baterijom",
  "peakShaved": "Smanjeno vršno opterećenje",
  "significantPeakShavings": "Najznačajnije smanjenje vršnih opterećenja",
  "electric_energy": "Električna energija",
  "thermal_energy": "Toplinska energija",
  "water_consumption": "Potrošnja vode",
  "ev_charging": "Punjenje EV",
  "inverter": "Solarna elektrana",
  "battery": "Baterija",
  "batterySoC": "Stanje napunjenosti baterije (%)",
  "stateOfCharge": "Stanje napunjenosti",
  "chargingPowerKw": "Snaga punjenja (kW)",
  "dischargingPowerKw": "Snaga pražnjenja (kW)",
  "batteryPowerKw": "Snaga baterije (kW)",
  "consumptionKw": "Potrošnja (kW)",
  "generationKw": "Proizvodnja (kW)",
  "importKw": "Uvoz (kW)",
  "exportKw": "Izvoz (kW)"
}


  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'hr', // Default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;