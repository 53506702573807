import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';

const BatteryScreen = () => {
  const [batteryData, setBatteryData] = useState([]);

  useEffect(() => {
    // Fetch battery charge sources data
    axios
      .get('https://menea.krtech.dev/api/battery-data') // Change this to your backend endpoint
      .then((response) => {
        const data = response.data.map((item) => ({
          time: item.time,
          formattedTime: moment(item.time).format('HH:mm'),
          batteryCharge: item.batteryCharge,
        }));
        setBatteryData(data);
      })
      .catch((error) => {
        console.error('Error fetching battery charge data:', error);
      });
  }, []);

  const containerStyle = {
    flex: 1,
    padding: '20px 0',
    overflowY: 'auto',
  };

  const chartWrapperStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '20px',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '300px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  const explanationStyle = {
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: '10px',
    marginBottom: '20px',
    maxWidth: '90%',
  };

  return (
    <div style={containerStyle}>
      {/* Battery Charge Chart */}
      <h2 style={titleStyle}>Battery Charge Data - Last 24 hours</h2>
      <div style={chartWrapperStyle}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart
              data={batteryData}
              margin={{ top: 10, right: 30, left: 20, bottom: 40 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="formattedTime"
                label={{
                  value: 'Time (HH:mm)',
                  position: 'insideBottom',
                  offset: -5,
                }}
                tick={{ fontSize: 12 }}
                angle={-45}
                textAnchor="end"
                height={60}
                interval={5}
              />
              <YAxis
                label={{
                  value: 'Battery Charge (kWh)',
                  angle: -90,
                  position: 'insideLeft',
                  offset: 10,
                }}
              />
              <Tooltip labelFormatter={(value) => `Time: ${value}`} />
              <Legend verticalAlign="top" height={36} />
              <Area
                type="monotone"
                dataKey="batteryCharge"
                stroke="#82ca9d"
                fill="#82ca9d"
                name="Battery Charge (kWh)"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        <p style={explanationStyle}>
          This graph displays the battery charge levels over the last 24 hours.
        </p>
      </div>
    </div>
  );
};

export default BatteryScreen;
