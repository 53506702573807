// src/components/Animation.jsx
import React from 'react';

const Animation = () => {
  const videoStyle = {
    width: '100%',
    height: '100%',
    // objectFit: 'cover',
  };

  return (
    <video
      style={videoStyle}
      src="https://menea.krtech.dev/public/MENEA.mp4"
      muted
      autoPlay
      loop
    />
  );
};

export default Animation;