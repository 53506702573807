// src/components/InverterScreen.jsx

import React, { useState, useEffect } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const InverterScreen = () => {
  const { t } = useTranslation();
  const [todayData, setTodayData] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [loading, setLoading] = useState(true);

  // Use the provided data for today's data
  const fetchTodayData = () => {
    // Provided data
    const providedData = [
    {
        "componentId": "12394771",
        "channelId": "Measurement.GridMs.TotW.Pv",
        "min": 0.0,
        "max": 20000.0,
        "values": [
            {
                "time": "2024-09-24T22:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T22:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T22:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T23:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T23:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T23:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-24T23:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T00:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T00:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T00:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T00:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T01:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T01:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T01:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T01:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T02:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T02:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T02:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T02:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T03:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T03:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T03:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T03:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T04:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T04:15:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T04:30:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T04:45:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T05:00:00Z",
                "value": 0.0,
                "values": null
            },
            {
                "time": "2024-09-25T05:15:00Z",
                "value": 168.0,
                "values": null
            },
            {
                "time": "2024-09-25T05:30:00Z",
                "value": 624.0,
                "values": null
            },
            {
                "time": "2024-09-25T05:45:00Z",
                "value": 920.0,
                "values": null
            },
            {
                "time": "2024-09-25T06:00:00Z",
                "value": 1076.0,
                "values": null
            },
            {
                "time": "2024-09-25T06:15:00Z",
                "value": 1288.0,
                "values": null
            },
            {
                "time": "2024-09-25T06:30:00Z",
                "value": 1184.0,
                "values": null
            },
            {
                "time": "2024-09-25T06:45:00Z",
                "value": 1492.0,
                "values": null
            },
            {
                "time": "2024-09-25T07:00:00Z",
                "value": 1836.0,
                "values": null
            },
            {
                "time": "2024-09-25T07:15:00Z",
                "value": 3644.0,
                "values": null
            },
            {
                "time": "2024-09-25T07:30:00Z",
                "value": 7804.0,
                "values": null
            },
            {
                "time": "2024-09-25T07:45:00Z",
                "value": 7180.0,
                "values": null
            },
            {
                "time": "2024-09-25T08:00:00Z",
                "value": 12944.0,
                "values": null
            },
            {
                "time": "2024-09-25T08:15:00Z",
                "value": 13684.0,
                "values": null
            },
            {
                "time": "2024-09-25T08:30:00Z",
                "value": 14496.0,
                "values": null
            },
            {
                "time": "2024-09-25T08:45:00Z",
                "value": 14628.0,
                "values": null
            },
            {
                "time": "2024-09-25T09:00:00Z",
                "value": 13304.0,
                "values": null
            },
            {
                "time": "2024-09-25T09:15:00Z",
                "value": 13800.0,
                "values": null
            }
        ]
    },
    {
        "componentId": "12394771",
        "channelId": "Measurement.Metering.TotWhOut.Pv",
        "min": 0.0,
        "max": 25218.0,
        "values": [
            {
                "time": "2024-09-25T22:00:00Z",
                "value": 25218.0,
                "values": null
            }
        ]
    }
]

    // Process the provided data
    const values = providedData[0].values || [];
    const data = values.map((item) => ({
      time: moment(item.time).format('HH:mm'),
      value: item.value / 1000, // Convert Wh to kWh
    }));

    setTodayData(data);
  };

  // Existing functions to fetch monthly and yearly data
  const fetchMonthlyData = async () => {
    try {
      const response = await axios.get('https://menea.krtech.dev/api/api/inverter/monthly');
      const data = response.data[0].values.map((item) => ({
        time: moment(item.time).format('MMM YYYY'),
        value: item.value / 1000, // Convert Wh to kWh
      }));
      setMonthlyData(data);
    } catch (error) {
      console.error('Error fetching monthly data:', error.response?.data || error.message);
    }
  };

  const fetchYearlyData = async () => {
    try {
      const response = await axios.get('https://menea.krtech.dev/api/api/inverter/yearly');
      const data = response.data[0].values.map((item) => ({
        time: moment(item.time).format('YYYY'),
        value: item.value / 1000, // Convert Wh to kWh
      }));
      setYearlyData(data);
    } catch (error) {
      console.error('Error fetching yearly data:', error.response?.data || error.message);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      fetchTodayData(); // Use provided data
      await fetchMonthlyData();
      await fetchYearlyData();
      setLoading(false);
    };
    fetchData();
  }, []);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  if (loading) {
    return <div style={{ textAlign: 'center' }}>{t('loading')}</div>;
  }

  return (
    <div style={containerStyle}>
      <h2 style={titleStyle}>{t('inverterData')}</h2>

      {/* Today's Data Chart */}
      <h3 style={titleStyle}>{t('todayProduction')}</h3>
      {todayData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={todayData}>
                <defs>
                  <linearGradient id="colorToday" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ff7300" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ff7300" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('time'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kWh"
                  label={{ value: t('energyKwh'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kWh`, t('energy')]}
                  labelFormatter={(label) => `${t('time')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#ff7300"
                  fillOpacity={1}
                  fill="url(#colorToday)"
                  name={t('production')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('noData')}</p>
      )}

      {/* Monthly Data Chart */}
      <h3 style={titleStyle}>{t('monthlyProduction')}</h3>
      {monthlyData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={monthlyData}>
                <defs>
                  <linearGradient id="colorMonthly" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('month'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kWh"
                  label={{ value: t('energyKwh'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kWh`, t('energy')]}
                  labelFormatter={(label) => `${t('month')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#82ca9d"
                  fillOpacity={1}
                  fill="url(#colorMonthly)"
                  name={t('production')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('noData')}</p>
      )}

      {/* Yearly Data Chart */}
      <h3 style={titleStyle}>{t('yearlyProduction')}</h3>
      {yearlyData.length > 0 ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <div style={chartContainerStyle}>
            <ResponsiveContainer>
              <AreaChart data={yearlyData}>
                <defs>
                  <linearGradient id="colorYearly" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#ffc658" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#ffc658" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="time"
                  label={{
                    value: t('year'),
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  unit="kWh"
                  label={{ value: t('energyKwh'), angle: -90, position: 'insideLeft' }}
                />
                <Tooltip
                  formatter={(value) => [`${value} kWh`, t('energy')]}
                  labelFormatter={(label) => `${t('year')}: ${label}`}
                />
                <Legend verticalAlign="top" height={36} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#ffc658"
                  fillOpacity={1}
                  fill="url(#colorYearly)"
                  name={t('production')}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: 'center' }}>{t('noData')}</p>
      )}
    </div>
  );
};

export default InverterScreen;
