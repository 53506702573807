import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ElectricalEnergyScreen = () => {
  const { t } = useTranslation();

  const [electricityData, setElectricityData] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState('today');

  // Base URL of your API server
  const API_BASE_URL = 'https://menea.krtech.dev/api'; // Replace with your actual API base URL

  const timeRanges = ['today', 'last 30 days', 'last year'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch overall electricity data
        const response = await axios.get(`${API_BASE_URL}/electricity/data`, {
          params: {
            period: selectedTimeRange,
          },
        });

        // Process the data
        const data = response.data.map((dataPoint) => {
          let dateLabel;
          if (selectedTimeRange === 'today') {
            dateLabel = moment(dataPoint.timestamp).format('HH:mm');
          } else if (selectedTimeRange === 'last 30 days') {
            dateLabel = moment(dataPoint.timestamp).format('DD.MM');
          } else if (selectedTimeRange === 'last year') {
            dateLabel = moment(dataPoint.timestamp).format('MMM YYYY');
          }
          return {
            date: dateLabel,
            energy_kWh: parseFloat(dataPoint.energy_kWh) || 0,
          };
        });

        setElectricityData(data);
      } catch (error) {
        console.error('Error fetching electricity data:', error);
      }
    };

    fetchData();
  }, [selectedTimeRange]);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const buttonStyle = {
    marginRight: '10px',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      {/* Time Range Buttons */}
      <div style={buttonContainerStyle}>
        {timeRanges.map((range) => (
          <button
            key={range}
            style={{
              ...buttonStyle,
              backgroundColor: selectedTimeRange === range ? '#007bff' : '#e0e0e0',
              color: selectedTimeRange === range ? '#fff' : '#000',
            }}
            onClick={() => setSelectedTimeRange(range)}
          >
            {t(range)}
          </button>
        ))}
      </div>

      <h2 style={titleStyle}>{t('electricalEnergyConsumption')}</h2>

      {/* Electricity Consumption Chart */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer>
            <LineChart data={electricityData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={80} interval="preserveStartEnd" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="energy_kWh" stroke="#8884d8" name={t('energy')} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ElectricalEnergyScreen;