// src/components/Timer.jsx
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Timer = ({ duration = 20 }) => {
  const location = useLocation();
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    setTimeLeft(duration);
    const interval = setInterval(() => {
      setTimeLeft((prev) => (prev > 0 ? prev - 1 : duration));
    }, 1000);

    return () => clearInterval(interval);
  }, [location.pathname, duration]);

  return (
    <div style={{ margin: '20px', fontWeight: 'bold' }}>
      <p>Sljedeca stranica za: {timeLeft} sekundi</p>
    </div>
  );
};

export default Timer;