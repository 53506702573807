// src/components/ThermalEnergyScreen.jsx
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

const ThermalEnergyScreen = () => {
  const { t } = useTranslation();

  const [combinedData, setCombinedData] = useState([]);
  const [selectedTimeRange, setSelectedTimeRange] = useState('today');

  // Base URL of your proxy server
  const API_BASE_URL = 'https://menea.krtech.dev/api'; // Adjust if necessary

  const timeRanges = ['today', 'last 30 days', 'last year'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const now = moment();
        let startDate;
        let period;
        let resolution;

        // Adjust startDate, period, and resolution based on selectedTimeRange
        if (selectedTimeRange === 'today') {
          startDate = now.format('YYYY:M:D:0');
          period = 'hour';
          resolution = 24;
        } else if (selectedTimeRange === 'last 30 days') {
          startDate = now.clone().subtract(29, 'days').format('YYYY:M:D:0');
          period = 'day';
          resolution = 30;
        } else if (selectedTimeRange === 'last year') {
          startDate = now.clone().subtract(11, 'months').startOf('month').format('YYYY:M:D:0');
          period = 'month';
          resolution = 12;
        }

        // Initialize an empty object to store combined data
        const dataMap = {};

        // Helper function to create date labels
        const formatDateLabel = (dateString) => {
          if (selectedTimeRange === 'today') {
            return moment(dateString).format('HH:mm');
          } else if (selectedTimeRange === 'last 30 days') {
            return moment(dateString).format('DD.MM');
          } else if (selectedTimeRange === 'last year') {
            return moment(dateString).format('MMM YYYY');
          }
          return dateString;
        };

        // Fetch data for Pyrolytic Furnace
        const pyroRequest = [
          {
            i: 0,
            t: 'c20231.energija_piroliza',
            p: period,
            s: startDate,
            sc: 1,
            cum: 1,
            res: resolution,
            page: 616,
          },
        ];

        const pyroResponse = await axios.post(`${API_BASE_URL}/cybro/graph`, pyroRequest);

        const pyroLabels = pyroResponse.data.labels || [];
        const pyroValues = pyroResponse.data.data[0] || [];

        pyroLabels.forEach((label, index) => {
          const dateLabel = formatDateLabel(label);
          if (!dataMap[dateLabel]) {
            dataMap[dateLabel] = { date: dateLabel };
          }
          dataMap[dateLabel].pyrolyticFurnace = parseFloat(pyroValues[index]) || 0;
        });

        // Fetch data for Gas Consumption
        const gasRequest = [
          {
            i: 0,
            t: 'c20231.plin_cnt[1]',
            p: period,
            s: startDate,
            sc: 1,
            cum: 1,
            res: resolution,
            page: 614,
          },
        ];

        const gasResponse = await axios.post(`${API_BASE_URL}/cybro/graph`, gasRequest);

        const gasLabels = gasResponse.data.labels || [];
        const gasValues = gasResponse.data.data[0] || [];

        gasLabels.forEach((label, index) => {
          const dateLabel = formatDateLabel(label);
          if (!dataMap[dateLabel]) {
            dataMap[dateLabel] = { date: dateLabel };
          }
          dataMap[dateLabel].gasConsumption = parseFloat(gasValues[index]) || 0;
        });

        // Fetch data for Heat Pump
        const heatPumpResponse = await axios.get(`${API_BASE_URL}/heatpump/data`, {
          params: {
            period: selectedTimeRange,
          },
        });

        // Process heat pump data
        const heatPumpRawData = heatPumpResponse.data;

        heatPumpRawData.forEach((dataPoint) => {
          const dateLabel = formatDateLabel(dataPoint.timestamp);
          if (!dataMap[dateLabel]) {
            dataMap[dateLabel] = { date: dateLabel };
          }
          dataMap[dateLabel].heatPump = parseFloat(dataPoint.energy_kWh) || 0;
        });

        // Convert dataMap to an array and sort by date
        const combinedDataArray = Object.values(dataMap).sort((a, b) => {
          return moment(a.date, 'HH:mm DD.MM MMM YYYY').valueOf() - moment(b.date, 'HH:mm DD.MM MMM YYYY').valueOf();
        });

        setCombinedData(combinedDataArray);
      } catch (error) {
        console.error('Error fetching thermal energy data:', error);
      }
    };

    fetchData();
  }, [selectedTimeRange]);

  const containerStyle = {
    flex: 1,
    padding: '20px',
    overflowY: 'auto',
  };

  const chartContainerStyle = {
    width: '95%',
    height: '400px',
    marginBottom: '20px',
  };

  const titleStyle = {
    textAlign: 'center',
    marginBottom: '10px',
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const buttonStyle = {
    marginRight: '10px',
    padding: '10px 20px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      {/* Time Range Buttons */}
      <div style={buttonContainerStyle}>
        {timeRanges.map((range) => (
          <button
            key={range}
            style={{
              ...buttonStyle,
              backgroundColor: selectedTimeRange === range ? '#007bff' : '#e0e0e0',
              color: selectedTimeRange === range ? '#fff' : '#000',
            }}
            onClick={() => setSelectedTimeRange(range)}
          >
            {t(range)}
          </button>
        ))}
      </div>

      <h2 style={titleStyle}>{t('thermalEnergyConsumption')}</h2>

      {/* Combined Chart */}
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={chartContainerStyle}>
          <ResponsiveContainer>
            <BarChart data={combinedData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" angle={-45} textAnchor="end" height={80} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar dataKey="pyrolyticFurnace" fill="#8884d8" name={t('pyrolyticFurnace')} />
              <Bar dataKey="heatPump" fill="#ffc658" name={t('heatPump')} />
              <Bar dataKey="gasConsumption" fill="#82ca9d" name={t('gasConsumption')} />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default ThermalEnergyScreen;